import React, { useState, useEffect } from 'react';
import Style from './Navbar.module.css';
import logo from '../../assets/images/logo.png';
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSubMenuOpen1, setIsSubMenuOpen1] = useState(false);
    const [isSubMenuOpen2, setIsSubMenuOpen2] = useState(false);
    const [isSticky, setIsSticky] = useState(false);

    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 0) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleSubMenu1 = () => {
        setIsSubMenuOpen1(!isSubMenuOpen1);
        setIsSubMenuOpen2(false);
    };

    const toggleSubMenu2 = () => {
        setIsSubMenuOpen2(!isSubMenuOpen2);
        setIsSubMenuOpen1(false);
    };

    const FILE_PATH = location.pathname;

    return (
        <div className={`${Style.Navbar__Wrapper} ${isSticky ? Style.Sticky : ''}`}>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-6 col-lg-2'>
                        <Link to="/" className={Style.Nav__Logo}>
                            <img src={logo} alt="Payyit" />
                        </Link>
                    </div>
                    <div className='col-6 d-lg-none text-end'>
                        <button type='button' className={Style.Navbar__Toggle} onClick={toggleMenu}><i className={`fa-regular ${!isMenuOpen ? 'fa-bars' : 'fa-times'} d-lg-block`} ></i></button>
                    </div>
                    <div className={`col-lg-10 ${isMenuOpen ? '' : 'd-none'} d-lg-block`}>
                        <nav role="navigation" className={Style.Nav__Menu}>
                            <ul className={Style.Nav__List}>
                                <li>
                                    <span className={Style.Nav__Link} onClick={toggleSubMenu1}>Features</span>
                                    <button className={`${Style.Sub__Toggle} ${isSubMenuOpen1 ? Style.Active : ''}`} type='button' onClick={toggleSubMenu1}>
                                        <i className='fa-solid fa-angle-down'></i>
                                    </button>
                                    <ul className={isSubMenuOpen1 ? '' : 'd-none d-lg-block'}>
                                        <li className={FILE_PATH === '/invoicing' ? Style.Active : ''}><Link className={Style.Nav__Link} to="/invoicing">Invoicing</Link></li>
                                        <li className={FILE_PATH === '/recurring-billing' ? Style.Active : ''}><Link className={Style.Nav__Link} to="/recurring-billing">Recurring Invoicing</Link></li>
                                        <li className={FILE_PATH === '/payments' ? Style.Active : ''}><Link className={Style.Nav__Link} to="/payments">Payments</Link></li>
                                        <li className={FILE_PATH === '/accounting' ? Style.Active : ''}><Link className={Style.Nav__Link} to="/accounting">Accounting</Link></li>
                                        {/* <li><Link to="/receipts">Mobile receipts</Link></li> */}
                                        <li className={FILE_PATH === '/advisors' ? Style.Active : ''}><Link className={Style.Nav__Link} to="/advisors">Advisors</Link></li>
                                        <li className={FILE_PATH === '/payyitme' ? Style.Active : ''}><Link className={Style.Nav__Link} to="/payyitme">Payyit.Me Lynk</Link></li>
                                        <li className={FILE_PATH === '/bluecard' ? Style.Active : ''}><Link className={Style.Nav__Link} to="/bluecard">Blue Visa Debit</Link></li>
                                    </ul>
                                </li>
                                <li className={FILE_PATH === '/pricing' ? Style.Active : ''}><Link className={Style.Nav__Link} to="/pricing">Pricing</Link></li>
                                {/* <li><Link to="/blog">Blog</Link></li> */}
                                <li>
                                    <span className={Style.Nav__Link} onClick={toggleSubMenu2}>More</span>
                                    <button className={`${Style.Sub__Toggle} ${isSubMenuOpen2 ? Style.Active : ''}`} type='button' onClick={toggleSubMenu2}>
                                        <i className='fa-solid fa-angle-down'></i>
                                    </button>
                                    <ul className={isSubMenuOpen2 ? '' : 'd-none d-lg-block'}>
                                        {/* <li><Link to="/help">How support works</Link></li> */}
                                        <li><Link className={Style.Nav__Link} to="https://payyit.ai" target='_blank'>Payyit AI Suite</Link></li>                                        
                                        <li><Link className={Style.Nav__Link} to="https://payyit.io" target='_blank'>Payyit eBook Store</Link></li>
                                        <li><Link className={Style.Nav__Link} to="https://payyitinc.tapfiliate.com" target='_blank'>Payyit Referral Hub</Link></li>
                                    </ul>
                                </li>
                            </ul>
                            <span className={Style.Nav__Divider}></span>
                            <div className='d-flex'>
                                <Link className={`${Style.Nav__Button} Blue__Button Outline py-2 me-3 me-lg-0`} to='https://dashboard.payyit.com/signin'>Sign In</Link>
                                <Link className={`${Style.Nav__Button} Blue__Button py-2`} to='https://dashboard.payyit.com/signup'>Sign Up</Link>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
